var matches;
//clear the searchbox - because if we navigate backwards we want it to be empty
$("#quick_search").val("");
$("#quick_search").on('keyup', function(event)
 {
    var len = $(this).val().length;
    var searchString = $(this).val();

    // 2 characters narrows down results enough
    // we use these 2 characters to bring back a base list from the server

    //CHECK IF KEY IS THE ENTER KEY
    if(len > 0 && event.keyCode == 13){
      $('#frm_quickSearch').submit();

    }
    else {
        if (len == 4 && !$.isNumeric(searchString))
        {
          $.ajax({
              type: "GET",
              url: searchBarUri + "/" + searchString,
              success:function(data)
              {
                 // store the matches in a var so we don't need to query the db again
                 matches = data;
                 parseMatches(matches);
              }
           });
        }
        else if(len > 4)
        {
           checkCacheForMatches(searchString);
        }
        else
        {
           $("#search-matches").html('');
           $( "#search-matches" ).hide();
        }
    }


});

function parseMatches(matches)
{
    // Check that we actually have some data (always returns square brackets if empty so check for more than 2 chars
    if(matches.length > 4){
        var list ="";
        // iterate through each json element
        $.each($.parseJSON(matches), function(){
            //append list item for each name we have found to list var
            list += "<li class='resultItem' data-id='"+ this.id +"'>" + this.name + "</li>";
        });

        $("#search-matches").html("<ul id='searchMatchResults' >" + list + "</ul>");
        $( "#search-matches" ).show();
    }
    else{
        $("#search-matches").html("There are no matches");
        $( "#search-matches" ).hide();
    }
}

function checkCacheForMatches(searchString)
{
    try {
        var list="";
        $.each($.parseJSON(matches), function(){
            var isAdded = false;
            var user = this.name;
            var id = this.id;

            $.each(this, function(k,v){
                if (v != null)
                {
                    if(!isAdded)
                    {
                        var value = v.toString();
                        if(value.toLowerCase().indexOf(searchString.toLowerCase()) > -1)
                        {
                            list += "<li class='resultItem' data-id='"+ id +"'>" + value + "</li>";
                            isAdded=true;
                        }
                    }
                }
             });
        });

        $("#search-matches").html("<ul id='searchMatchResults' >" + list + "</ul>");
        if(list.length > 0 )
        {
            $( "#search-matches" ).show();
        }
        else {
            $( "#search-matches" ).hide();
        }
    }
    catch(err) {
    }
}

// On clicking a matched item, we navigate to the users profile page by referencing their id
$(document).on('click', '.resultItem', function(){
    var id = $(this).attr('data-id');
    window.location.href=staffViewUri + "/" + id;
});
